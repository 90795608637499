<template>
<div>
  <Loading :isLoading= "this.isLoading" />
  <div>
    <p style="padding:14px 14px 0px 14px;font-size:20px;color:#810042;font-weight:700;font-family:Lato;">Carga de Documentos</p>
  </div>
  <center>
  <div class="grid grid-cols-1 gap-4 m-5" style="" >
    <div style="overflow-x:auto;background:#fff;border-width: 1px;border-style: solid;border-color:#85C875;border-radius:7px;box-shadow: 0 0 5px #9ecaed;">
      <br>
      <br>
      <div class="flex" style="margin-top:0px;">
        <div class="w-full">
          <p style="text-align:center;padding-left: 40px;padding-right: 40px;">
              Favor de cargar los documentos faltantes en formato .pdf, .png o .jpeg.
          </p>
        </div>
      </div>
      <div style="margin-top:20px;">
        <table class="width200">
          <thead>
            <tr>
              <th>Documento</th>
              <th>Estatus</th>
              <th>Nombre del archivo</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr class="trTable" style="">
              <td style="font-size:16px;font-family:Roboto;font-weight: 400;">
                Pago por Parte Social
              </td>
              <td style="height:40px;"><Check v-show="loadCheckRPPSocial" /></td>
              <td style=""><div class="titles"><i>{{ rppsocial }}</i></div></td>
              <td style="">
                <div v-show="!loadCheckRPPSocial" style="display:flex;justify-content:right;margin-bottom:10px;">
                  <div>
                    <p class="subtitle" style="margin-top:5px;margin-bottom:0px;">
                      Fecha de depósito:
                    </p>
                    <date-picker
                      simple
                      color="#810042"
                      placeholder="Fecha de depósito"
                      locale="es"
                      input-format="DD/MM/YYYY"
                      @change="selectDate"
                      :localeConfig="{
                        es: {
                          displayFormat: 'DD/MM/YYYY',
                            lang: {
                              year: 'Año',
                              month: 'Mes',
                              day: 'Día',
                              label: '',
                              submit: 'Seleccionar',
                              cancel: 'Cancelar',
                              now: 'Hoy',
                              nextMonth: 'Siguiente',
                              prevMonth: 'Anterior',
                            },
                          }
                      }" />
                  </div>
                </div>
                <input type="button" class="btnMainNoResponsive" v-show="rppsocial==''" value="Cargar archivo" v-on:click="getFileRPPSocial()" />
                <button v-show="rppsocial==''" v-on:click="getFileRPPSocial()" class="btnLoadMobile" style="text-decoration:none;"><img height="40" width="40" src="@/assets/Cuenta/cargar.svg" style="width:28px;margin-left:1px;"></button>
                <input type="button" class="btnDeleteDocument btnDeleteNoResponsive" v-show="rppsocial!=''" value="Eliminar" v-on:click="deleteDocument(0, 'RPPSOCIAL', rppsocial)" />
                <button v-show="rppsocial!=''" v-on:click="deleteDocument(0, 'RPPSOCIAL', rppsocial)" class="btnDeleteMobile" style="text-decoration:none;"><img height="40" width="40" src="@/assets/Cuenta/eliminar.svg" style="width:28px;margin-left:1px;"></button>
                <div style='height: 0px;width:0px; overflow:hidden;'><input id="upfileRPPSocial" type="file" accept=".pdf,.png,.PNG,.jpeg" value="" @change="loadDocument($event, 'RPPSOCIAL')"/></div>
              </td>
            </tr>

            <tr class="trTable" style="vertical-align:top;" v-if="assisted_request == false">
              <td style="font-size:16px;font-family:Roboto;font-weight: 400;">Identificación</td>
              <td style="height:40px;"><Check v-show="loadCheckID" /></td>
              <td style="vertical-align:top;">
                <table class="table-scroll2 subtableid">
                  <thead>
                  </thead>
                  <tbody class="">
                    <tr v-for="item in documentsArray.identifications" :key="item.id">
                    <td style="vertical-align:top;padding-top:0;padding-left:0;"><div class="titles"><i>{{item.name}}</i></div></td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td style="">
                <input type="button" v-show="!loadCheckID" class="btnMainNoResponsive" value="Completar registro" v-on:click="goToValidate()" />
                <p v-show="loadCheckID" class="textMati" style="">Documento obtenido <br/>de la verificación</p>
              </td>
            </tr>

            <tr style="vertical-align:top;" class="trTable" v-if="assisted_request == true">
              <td style="font-size:16px;font-family:Roboto;font-weight: 400;">Identificación</td>
              <td style="height:40px;"><Check v-show="loadCheckID" /></td>
              <td style="height:50px;vertical-align:top;" colspan="2">
                <table class="table-scroll small-first-col subtable">
                  <thead>
                  </thead>
                  <tbody class="body-half-screen">
                    <tr v-for="item in documentsArray.identifications" :key="item.id">
                      <td class="tdTable" style="">
                        <div class="grid grid-cols-1">
                          <div class="titlesProofIncome">
                            <i>{{item.name}}</i>
                          </div>
                          <div>
                            <button v-on:click="deleteDocument(item.id, 'IO', item.name)" class="btnDeleteMobile" style="text-decoration:none;"><img height="40" width="40" src="@/assets/Cuenta/eliminar.svg" style="width:28px;margin-left:1px;"></button>
                          </div>
                        </div>
                      </td>
                      <td class= "tdButton" style="padding-left:0;"><input type="button" class="btnDeleteNoResponsive" value="Eliminar" v-on:click="deleteDocument(item.id, 'IO', item.name)" /></td>
                    </tr>
                    <input type="button" class="btnMainProofIncomeNoResponsive" value="Cargar más archivos" 
                    :disabled="!letPushIdentifications" v-bind:style="{opacity: letPushIdentifications ? activeColor : activeColor2}" 
                    v-on:click="getFileIdentification()" style="" />
                    <button :disabled="!letPushIdentifications" v-bind:style="{opacity: letPushIdentifications ? activeColor : activeColor2}" v-on:click="getFileIdentification()" class="btnLoadMobile" style="text-decoration:none;"><img height="40" width="40" src="@/assets/Cuenta/cargar.svg" style="width:28px;margin-left:1px;"></button>
                    <div style='height:0px;width:0px; overflow:hidden;'><input id="upfileIdentification" type="file" multiple="true" accept=".pdf,.png,.PNG,.jpeg" 
                    @change="loadDocument($event, 'IO')" style="float:right" /></div>
                  </tbody>
                </table>
              </td>
            </tr>

            <tr class="trTable">
              <td style="font-size:16px;font-family:Roboto;font-weight: 400;">
                  C.U.R.P.
                  <p style="font-size:12px;font-family:Roboto;font-weight: 300;"><a href="https://www.gob.mx/curp/" target="_blank">Descargar aquí.</a> </p>
              </td>
              <td style="height:40px;"><Check v-show="loadCheckCURP" /></td>
              <td style=""><div class="titles"><i style="width:100%;">{{ curp }}</i></div></td>
              <td style="">
                <input type="button" class="btnMainNoResponsive" v-show="curp==''" value="Cargar archivo" v-on:click="getFileCurp()" />
                <button v-show="curp==''" v-on:click="getFileCurp()" class="btnLoadMobile" style="text-decoration:none;"><img height="40" width="40" src="@/assets/Cuenta/cargar.svg" style="width:28px;margin-left:1px;"></button>
                <input type="button" class="btnDeleteDocument btnDeleteNoResponsive" v-show="curp!=''" value="Eliminar" v-on:click="deleteDocument(0, 'CURP', curp)" />
                <button v-show="curp!=''" v-on:click="deleteDocument(0, 'CURP', curp)" class="btnDeleteMobile" style="text-decoration:none;"><img src="@/assets/Cuenta/eliminar.svg" style="width:28px;margin-left:1px;"></button>
                <div style='height: 0px;width:0px; overflow:hidden;'><input id="upfileCurp" type="file" accept=".pdf,.png,.PNG,.jpeg" value="" @change="loadDocument($event, 'CURP')"/></div>
              </td>
            </tr>
            <tr class="trTable">
              <td style="font-size:16px;font-family:Roboto;font-weight: 400;">
                Comprobante de Domicilio
                <p style="font-size:12px;font-family:Roboto;font-weight: 300;">A nombre del socio solicitante con una antigüedad no mayor a 3 meses (Luz, agua, teléfono, Estado de cuenta bancario).</p>
              </td>
              <td style="height:40px;"><Check v-show="loadCheckProofResidency" /></td>
              <td style=""><div class="titles"><i>{{ proofResidency }}</i></div></td>
              <td style="height:50px;">
                <input type="button" class="btnMainNoResponsive" v-show="proofResidency==''" value="Cargar archivo" v-on:click="getFileProofResidency()" />
                <button v-show="proofResidency==''" v-on:click="getFileProofResidency()" class="btnLoadMobile" style="text-decoration:none;"><img height="40" width="40" src="@/assets/Cuenta/cargar.svg" style="width:28px;margin-left:1px;"></button>
                <input type="button" class="btnDeleteDocument btnDeleteNoResponsive" v-show="proofResidency!=''" value="Eliminar" v-on:click="deleteDocument(0, 'CD', proofResidency)" />
                <button v-show="proofResidency!=''" v-on:click="deleteDocument(0, 'CD', proofResidency)" class="btnDeleteMobile" style="text-decoration:none;"><img height="40" width="40" src="@/assets/Cuenta/eliminar.svg" style="width:28px;margin-left:1px;"></button>
                <div style='height: 0px;width:0px; overflow:hidden;'><input id="upfileProofResidency" type="file" accept=".pdf,.png,.PNG,.jpeg" value=""   v-on:change="loadDocument($event, 'CD')"  /></div>
              </td>
            </tr>
            <tr class="trTable">
                <td style="vertical-align:top;height:40px;font-size:16px;font-family:Roboto;font-weight: 400;">
                  Comprobantes de Ingresos
                  <p style="font-size:12px;font-family:Roboto;font-weight: 300;">De los últimos tres meses.</p>
                </td>
                <td style="vertical-align:top;height:80px;height:40px;"><Check v-show="loadCheckProofIncome" /></td>
                
                <td style="vertical-align:top;" colspan="2">
                  <table class="table-scroll small-first-col subtable">
                    <thead>
                    </thead>
                    <tbody class="body-half-screen">
                      <tr v-for="item in documentsArray.proofIncomes" :key="item.id">
                        <td class="tdTable" style="">
                          <div class="grid grid-cols-1">
                            <div class="titlesProofIncome">
                              <i>{{item.name}}</i>
                            </div>
                            <div>
                              <button v-on:click="deleteDocument(item.id, 'CI', item.name)" class="btnDeleteMobile" style="text-decoration:none;"><img height="40" width="40" src="@/assets/Cuenta/eliminar.svg" style="width:28px;margin-left:1px;"></button>
                            </div>
                          </div>
                          <!-- <input type="button" class="btnDeleteDocument btnDeleteResponsive" value="Eliminar" v-on:click="deleteDocument(item.id, 'CI', item.name)" /> -->
                          
                        </td>
                        <td class= "tdButton" style="padding-left:0;"><input type="button" class="btnDeleteNoResponsive" value="Eliminar" v-on:click="deleteDocument(item.id, 'CI', item.name)" /></td>
                      </tr>
                      <input type="button" class="btnMainProofIncomeNoResponsive" value="Cargar más archivos" 
                      :disabled="!letPushProofIncome" v-bind:style="{opacity: letPushProofIncome ? activeColor : activeColor2}" 
                      v-on:click="getFileProofIncome()" style="" />
                      <button :disabled="!letPushProofIncome" v-bind:style="{opacity: letPushProofIncome ? activeColor : activeColor2}" v-on:click="getFileProofIncome()" class="btnLoadMobile" style="text-decoration:none;"><img height="40" width="40" src="@/assets/Cuenta/cargar.svg" style="width:28px;margin-left:1px;"></button>
                      <div style='height:0px;width:0px; overflow:hidden;'><input id="upfileProofIncome" type="file" multiple="true" accept=".pdf,.png,.PNG,.jpeg" value="" 
                      @change="loadDocument($event, 'CI')" style="float:right" /></div>
                    </tbody>
                  </table>
                </td>
            </tr>
            <tr class="trTable">
                <td style="vertical-align:top;height:40px;font-size:16px;font-family:Roboto;font-weight: 400;">
                  Estado de Cuenta
                  <p style="font-size:12px;font-family:Roboto;font-weight: 300;">Del último mes.</p>
                </td>
              <td style="height:40px;"><Check v-show="loadCheckFinancialStatement" /></td>
              <td style=""><div class="titles"><i>{{ financialStatement }}</i></div></td>
              <td style="">
                <input type="button" class="btnMainNoResponsive" v-show="financialStatement==''" value="Cargar archivo" v-on:click="getFileFinancialStatement()" />
                <button v-show="financialStatement==''" v-on:click="getFileFinancialStatement()" class="btnLoadMobile" style="text-decoration:none;"><img height="40" width="40" src="@/assets/Cuenta/cargar.svg" style="width:28px;margin-left:1px;"></button>
                <input type="button" class="btnDeleteDocument btnDeleteNoResponsive" v-show="financialStatement!=''" value="Eliminar" v-on:click="deleteDocument(0, 'EC', financialStatement)" />
                <button v-show="financialStatement!=''" v-on:click="deleteDocument(0, 'EC', financialStatement)" class="btnDeleteMobile" style="text-decoration:none;"><img src="@/assets/Cuenta/eliminar.svg" style="width:28px;margin-left:1px;"></button>
                <div style='height: 0px;width:0px; overflow:hidden;'><input id="upfileFinancialStatement" type="file" accept=".pdf,.png,.PNG,.jpeg" value="" @change="loadDocument($event, 'EC')"/></div>
              </td>
            </tr>
            <tr class="trTable" style="" v-if="assisted_request">
              <td style="font-size:16px;font-family:Roboto;font-weight: 400;">
                Autorizo de descuento
              </td>
              <td style="height:40px;"><Check v-show="loadCheckAudsctoAhorro" /></td>
              <td style=""><div class="titles"><i>{{ audsctoAhorro }}</i></div></td>
              <td style="">
                <input type="button" class="btnMainNoResponsive" v-show="audsctoAhorro==''" value="Cargar archivo" v-on:click="getFileAudsctoAhorro()" />
                <button v-show="audsctoAhorro==''" v-on:click="getFileAudsctoAhorro()" class="btnLoadMobile" style="text-decoration:none;"><img height="40" width="40" src="@/assets/Cuenta/cargar.svg" style="width:28px;margin-left:1px;"></button>
                <input type="button" class="btnDeleteDocument btnDeleteNoResponsive" v-show="audsctoAhorro!=''" value="Eliminar" v-on:click="deleteDocument(0, 'AUDSCTOAHORRO', audsctoAhorro)" />
                <button v-show="audsctoAhorro!=''" v-on:click="deleteDocument(0, 'AUDSCTOAHORRO', audsctoAhorro)" class="btnDeleteMobile" style="text-decoration:none;"><img height="40" width="40" src="@/assets/Cuenta/eliminar.svg" style="width:28px;margin-left:1px;"></button>
                <div style='height: 0px;width:0px; overflow:hidden;'><input id="upfileAudsctoAhorro" type="file" accept=".pdf,.png,.PNG,.jpeg" value="" @change="loadDocument($event, 'AUDSCTOAHORRO')"/></div>
              </td>
            </tr>
            <tr class="trTable trRFC">
              <td colspan="4" style="vertical-align:bottom;color:#726659;font-size:16px;font-family:Roboto;font-weight:700;">Agrega tu R.F.C. o Constancia de situación Fiscal.</td>
            </tr>
            <tr class="trTable" style="">
              <td style="font-size:16px;font-family:Roboto;font-weight: 400;">
                R.F.C. o Constancia de situación Fiscal
                <p style="font-size:12px;font-family:Roboto;font-weight: 300;"><a href="https://www.sat.gob.mx/aplicacion/login/53027/genera-tu-constancia-de-situacion-fiscal" target="_blank">Descargar aquí.</a> </p>
              </td>
              <td style="height:40px;"><Check v-show="loadCheckRFC" /></td>
              <td style=""><div class="titles"><i>{{ rfc }}</i></div></td>
              <td style="">
                <input type="button" class="btnMainNoResponsive" v-show="rfc==''" value="Cargar archivo" v-on:click="getFileRFC()" />
                <button v-show="rfc==''" v-on:click="getFileRFC()" class="btnLoadMobile" style="text-decoration:none;"><img height="40" width="40" src="@/assets/Cuenta/cargar.svg" style="width:28px;margin-left:1px;"></button>
                <input type="button" class="btnDeleteDocument btnDeleteNoResponsive" v-show="rfc!=''" value="Eliminar" v-on:click="deleteDocument(0, 'RFCFIEL', rfc)" />
                <button v-show="rfc!=''" v-on:click="deleteDocument(0, 'RFCFIEL', rfc)" class="btnDeleteMobile" style="text-decoration:none;"><img height="40" width="40" src="@/assets/Cuenta/eliminar.svg" style="width:28px;margin-left:1px;"></button>
                <div style='height: 0px;width:0px; overflow:hidden;'><input id="upfileRFC" type="file" accept=".pdf,.png,.PNG,.jpeg" value="" @change="loadDocument($event, 'RFCFIEL')"/></div>
              </td>
            </tr>
        
          </tbody>
          </table>
          <div style="margin-bottom:20px;">
            <div class="flex mt-5">
              <div class="w-full">
                <router-link :to="'/supervisor/cuenta/revision-solicitudes-ingreso/'+getRouterParams()" class="buttonSave" style="width:200px; height:30px; font-size:18px;">Atrás</router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </center>
</div>
</template>














<script>
import Header from "@/components/Supervisor/Header.vue"
import MenuLateral from '@/components/Supervisor/MenuLateral.vue'
import Loading from '@/components/Loading/VueLoading.vue'
import "@/assets/css/tailwind.css";


import Check from '@/components/Loading/VueCheck.vue'
import { getDataVerificationMati } from '@/api/user'
import Swal from "sweetalert2";
import {getTokenDecoden} from "@/helpers/tokenauth";
import Document from '@/classes/Document'
import Stripe from '@/classes/Stripe'
import moment from "moment";

export default {
  data() {
  return {
      activeColor: '1',
        activeColor2: '0.4',
        apiToken: "",
        // personId: "",
        ccapi: process.env.VUE_APP_CCAPI,
        multiApi:process.env.VUE_APP_MULTIAPI,
        isLoading: false,
        loadCheckID: false,
        loadCheckCURP: false,
        loadCheckProofResidency: false,
        loadCheckProofIncome: false,
        loadCheckFinancialStatement: false,
        loadCheckRFC: false,
        loadCheckRPPSocial: false,
        loadCheckAudsctoAhorro: false,
        identifications: [],
        curp: "",
        proofResidency: "",
        proofIncomes: [],
        rfc: "",
        rppsocial: "",
        audsctoAhorro: "",
        documentsArray: {
          identifications: [],
          documents: [],
          proofIncomes: []
        },
        financialStatement: "",
        member_id: "",
        mati_identity_id: "",
        isReady: false,
        existBeneficiaries: false,

        user_id:null,
        admission_id:"",
        admission_status: null,
        user_is_validated:false,
        data_verification_id: null,
        infoDocuments:null,
        message:null,
        nameDocumentsArray:[],
        base64Frontal:"",
        base64Trasera:"",
        extensionFrontal:"",
        extensionTrasera:"",
        client_classification:"",
        assisted_request: false,
        documentObjectClass: null,
        objectClassStripe: null,
        hasPayment: false,
        paymentDate:""
    };
  },
  filters:  {
  },
  name: 'Principal',
  components: {
    Header,
    Loading,
    MenuLateral,
    Check
  },
  props: {
    msg: String
  },
  watch: {
  },
  async mounted() {
    this.isLoading = true;
    this.objectClassStripe = new Stripe();
    this.documentObjectClass = new Document();

    await this.loadInfo();
  },
  async beforeMount() {
  },
  computed: {
    letPushProofIncome(){
      if (this.documentsArray.proofIncomes.length >= 18) {
        return false;
      }
      return true;
    },
    letPushIdentifications(){
      if (this.documentsArray.proofIncomes.length >= 18) {
        return false;
      }
      return true;
    },
  },
  methods:{
    async redirect() {
      // window.location.href = "/supervisor/cuenta/revision-solicitudes-ingreso/"+this.getRouterParams()
      this.$router.push({path:`/supervisor/cuenta/revision-solicitudes-ingreso/"${this.getRouterParams()}`});
    },
    Logout(){
      sessionStorage.clear();
      //localStorage.clear();
      // window.location.href = "/"
      this.$router.push({path:"/"});
    },
    getRouterParams() {
      return this.$route.params.requestId
    },
    async loadInfo(){
      var auth = getTokenDecoden();
      this.apiToken = sessionStorage.getItem("login")
      // this.personId = auth.obj.personnel_info.id
      this.admission_id = this.getRouterParams();
      this.getAdmissionRequestById();
      //this.getPayment();
      this.getDocuments();
    },
    async getDocuments() {
      this.isLoading = true;
      console.log();
      return await this.axios.get(this.ccapi + `/${process.env.VUE_APP_BUSINESS_FONCABSA}/admission_request/download_all_documents`, {
        params:{
          token_auth: sessionStorage.getItem("login"),
          admission_request_id: this.admission_id,
          remote_folder:"documentos_alta_socios"
        }
      }, { 'Content-Type': 'application/json' })
      .then(response => {
        this.infoDocuments= response.data.response.documents;
        console.log("infoDocuments: ",this.infoDocuments);
        this.setDocumentSelected(this.infoDocuments, true);
      }).catch(error => {
            Swal.fire({
              title: "Aviso",
              text: "Se presento un error al descargar los documentos.",
              icon: "error",
              confirmButtonColor: '#FEB72B',
            });
            // window.location.href = "/cuenta/principal";
            this.$router.push({path:"/cuenta/principal"});
      });
    },
    getFileCurp() {
      document.getElementById("upfileCurp").value = "";
      Swal.fire({
          title: "Aviso",
          text: '¿Desea cargar la CURP? ',
          icon: "question",
          showCancelButton: true,
          confirmButtonColor: '#FEB72B',
          cancelButtonColor: '#FEB72B',
          confirmButtonText: "SI",
          cancelButtonText: "NO"
      })
      .then((result) => {
        if (result.value) {
            document.getElementById("upfileCurp").click();
        }
      });
    },
    getFileProofResidency() {
      document.getElementById("upfileProofResidency").value = "";
      Swal.fire({
          title: "Aviso",
          text: '¿Desea cargar el Comprobante de Domicilio? ',
          icon: "question",
          showCancelButton: true,
          confirmButtonColor: '#FEB72B',
          cancelButtonColor: '#FEB72B',
          confirmButtonText: "SI",
          cancelButtonText: "NO"
      })
      .then((result) => {
        if (result.value) {
          document.getElementById("upfileProofResidency").click();
        }
      });
    },
    getFileProofIncome() {
      document.getElementById("upfileProofIncome").value = "";
      Swal.fire({
          title: "Aviso",
          text: '¿Desea cargar un Comprobante de Ingresos? ',
          icon: "question",
          showCancelButton: true,
          confirmButtonColor: '#FEB72B',
          cancelButtonColor: '#FEB72B',
          confirmButtonText: "SI",
          cancelButtonText: "NO"
      })
      .then((result) => {
        if (result.value) {
          document.getElementById("upfileProofIncome").click();
        }
      });
    },
    getFileIdentification() {
      document.getElementById("upfileIdentification").value = "";
      Swal.fire({
          title: "Aviso",
          text: '¿Desea cargar Identificacion? ',
          icon: "question",
          showCancelButton: true,
          confirmButtonColor: '#FEB72B',
          cancelButtonColor: '#FEB72B',
          confirmButtonText: "SI",
          cancelButtonText: "NO"
      })
      .then((result) => {
        if (result.value) {
          document.getElementById("upfileIdentification").click();
        }
      });
    },
    getFileFinancialStatement() {
      document.getElementById("upfileFinancialStatement").value = "";
      Swal.fire({
          title: "Aviso",
          text: '¿Desea cargar el Estado de Cuenta? ',
          icon: "question",
          showCancelButton: true,
          confirmButtonColor: '#FEB72B',
          cancelButtonColor: '#FEB72B',
          confirmButtonText: "SI",
          cancelButtonText: "NO"
      })
      .then((result) => {
        if (result.value) {
          document.getElementById("upfileFinancialStatement").click();
        }
      });
    },
    getFileRFC() {
      document.getElementById("upfileRFC").value ="";
      Swal.fire({
          title: "Aviso",
          text: '¿Desea cargar el RFC? ',
          icon: "question",
          showCancelButton: true,
          confirmButtonColor: '#FEB72B',
          cancelButtonColor: '#FEB72B',
          confirmButtonText: "SI",
          cancelButtonText: "NO"
      })
      .then((result) => {
        if (result.value) {
          document.getElementById("upfileRFC").click();
        }
      });
    },
    getFileRPPSocial() {
      if(this.paymentDate == ""){
        Swal.fire({
          title: "Aviso",
          text:
            "Favor de seleccionar una fecha de depósito.",
          icon: "warning",
          confirmButtonColor: '#FEB72B',
        });
        return;
      }

      document.getElementById("upfileRPPSocial").value ="";
      Swal.fire({
          title: "Aviso",
          text: '¿Desea cargar el pago por parte social? ',
          icon: "question",
          showCancelButton: true,
          confirmButtonColor: '#FEB72B',
          cancelButtonColor: '#FEB72B',
          confirmButtonText: "SI",
          cancelButtonText: "NO"
      })
      .then((result) => {
        if (result.value) {
          document.getElementById("upfileRPPSocial").click();
        }
      });
    },
    getFileAudsctoAhorro() {
      document.getElementById("upfileAudsctoAhorro").value ="";
      Swal.fire({
          title: "Aviso",
          text: '¿Desea cargar el autorizo de descuento? ',
          icon: "question",
          showCancelButton: true,
          confirmButtonColor: '#FEB72B',
          cancelButtonColor: '#FEB72B',
          confirmButtonText: "SI",
          cancelButtonText: "NO"
      })
      .then((result) => {
        if (result.value) {
          document.getElementById("upfileAudsctoAhorro").click();
        }
      });
    },
    existDocument(documents, newDocument) {
      let indexDocuments = documents.findIndex(d => d.name === newDocument);
      if (indexDocuments != -1) {
        return true;
      }
      return false;
    },
    loadDocument(event, type) {
      let files = event.target.files;
      this.isLoading = true;
      let fileBase64 = "";
      let filename = files[0].name;
      let extension = files[0].type.split('/')[1];
      const fileReader = new FileReader();
      fileReader.onerror = this.errorHandler;
      let fileNameWitUnderscore = filename.replace(/\s/g, '_');
      console.log("this.nameDocumentsArray: ",this.nameDocumentsArray);
      console.log("fileNameWitUnderscore: ",fileNameWitUnderscore);

      if(this.nameDocumentsArray.length > 0){
        if (this.existDocument(this.nameDocumentsArray, fileNameWitUnderscore)) {
          this.isLoading = false;
          Swal.fire({
            title: "Aviso",
            text: "El archivo seleccionado ya ha sido cargado",
            icon: "error",
            confirmButtonColor: '#FEB72B',
          });
          return;
        }
      }
      let documents_list=[];
      fileReader.addEventListener('load', () => {
        fileBase64 = fileReader.result.split(',')[1];
        event.target.value = null;
        //this.save(fileBase64, filename.split('.')[0].replace(/\s/g, '_'), extension, type);

        documents_list.push({
          name: filename.split('.')[0].replace(/\s/g, '_'),
          extension: extension,
          abbreviation: type,
          admission_request_id: this.admission_id,
          remote_folder: "documentos_alta_socios",
          file: fileBase64
        });
        let self = this;
        this.save(documents_list).then((response) => {
          self.isLoading = false;
        });
      })
      fileReader.readAsDataURL(files[0]);

    },
    errorHandler(evt) {
      let mensaje ="";
      switch(evt.target.error.code) {
        case evt.target.error.NOT_FOUND_ERR:
          mensaje = "Archivo no encontrado.";
          break;
        case evt.target.error.NOT_READABLE_ERR:
          mensaje = "El archivo no es leible."
          break;
        case evt.target.error.ABORT_ERR:
          break; // noop
        default:
          mensaje = "Ocurrió un error en la lectura del archivo.";
      };
      Swal.fire({
        title: "Aviso",
        text: mensaje,
        icon: "error",
        confirmButtonColor: '#FEB72B',
      });
    },
    deleteDocument(id, type, name) {
      Swal.fire({
          title: "Aviso",
          text: '¿Desea eliminar el siguiente documento? ',
          html: `<p>¿Desea eliminar el siguiente documento?<br/>
                  <span style="font-weight: bold; color:red">${name}</span>
                  </p>`,
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: '#FEB72B',
          cancelButtonColor: '#FEB72B',
          confirmButtonText: "SI",
          cancelButtonText: "NO"
      })
      .then((result) => {
        if (result.value) {
          this.isLoading = true;
          let index = -1;
          let finalDocument = {}
          if (type == "CI") {
            index = this.documentsArray.proofIncomes.findIndex(d => d.id === id);
            if (index > -1) {
              finalDocument = this.getFinalDocumentDelete(this.documentsArray.proofIncomes[index]);
            }
          } else if (type == "IO" && this.assisted_request){
            index = this.documentsArray.identifications.findIndex(d => d.id === id);
            if (index > -1) {
              finalDocument = this.getFinalDocumentDelete(this.documentsArray.identifications[index]);
            }
          } else {
            index = this.documentsArray.documents.findIndex(d => d.typeDocument === type);
            if (index > -1) {
              finalDocument = this.getFinalDocumentDelete(this.documentsArray.documents[index]);
            }
          }

          this.axios.post(this.ccapi + `/${process.env.VUE_APP_BUSINESS_FONCABSA}/admission_request/delete_document`, {
            token_auth: sessionStorage.getItem("login"),
            document_id: finalDocument[0].document_id
          },{ 'Content-Type': 'application/json'})
          .then(response => {
              this.cleanControls(type);
              this.validate();
              Swal.fire({
                  title: "Aviso",
                  text: "El archivo ha sido eliminado",
                  icon: "success",
                  confirmButtonColor: '#FEB72B',
              });
            console.log("type: ",type);
            console.log("nameDocumentsArray type: ",this.nameDocumentsArray);

            const i = this.nameDocumentsArray.findIndex( element => element.type == type);
            console.log("i: ",i);
            console.log("this.nameDocumentsArray.lenght",this.nameDocumentsArray.length);
            console.log("this.nameDocumentsArray.lenght",this.documentsArray.length);

            this.nameDocumentsArray.splice(i,1);
            //Object.values(this.documentsArray).splice(index,1);
            let dt1 =this.nameDocumentsArray;
            if (type == "CI") {
              this.documentsArray.proofIncomes.splice(index,1);
            }else if (type == "IO" && this.assisted_request){
              this.documentsArray.identifications.splice(index,1);
            } else {
              this.documentsArray.documents.splice(index,1);
            }
            let dt = this.documentsArray;
            console.log("this.nameDocumentsArray.lenght",this.nameDocumentsArray.length);
            console.log("this.nameDocumentsArray.lenght",this.documentsArray.length);
          }).catch( error => {
            console.log("Error al eliminar: ",error);
            Swal.fire({
              title: "Aviso",
              text: "Se presento un error al eliminar el archivo",
              icon: "error",
              confirmButtonColor: '#FEB72B',
            });
          })
          .finally(() => {
            this.isLoading = false;
          });
        }
      });
    },
    cleanControls(abbreviation) {
      switch (abbreviation) {
        case "CURP":
          this.curp = "";
          this.loadCheckCURP = false;
          break;
        case "CD":
          this.proofResidency = "";
          this.loadCheckProofResidency = false;
          break;
        case "CI":
          if (this.documentsArray.proofIncomes.length <= 0) {
            this.loadCheckProofIncome = false; 
          }
          break;
        case "EC":
          this.financialStatement = "";
          this.loadCheckFinancialStatement = false;
          break;
        case "RFCFIEL":
          this.rfc = "";
          this.loadCheckRFC = false;
          break;
        case "RPPSOCIAL":
          this.rppsocial = "";
          this.loadCheckRPPSocial = false;
          break;
        case "AUDSCTOAHORRO":
          this.audsctoAhorro = "";
          this.loadCheckAudsctoAhorro = false;
          break;
        default:
          break;
      }
    },
    getFileNameFromURL(url, paramName) {
      var url = url
      var urlVar = url.split('&');
        for (var i = 0; i < urlVar.length; i++) {
          var param = urlVar[i].split('=');
          if (param[0] == paramName) {
            return param[1];
          }
        }
      return null;
    },
    getFinalDocumentDelete(document){
      let finalDocumentArray = []
      
      finalDocumentArray.push({
        document_name: document.name,
        member_id: this.member_id,
        business_acronym:"FONCABSA",
        document_id: document.id
      });

      return finalDocumentArray;
    },
    save(document_list) {
      this.isLoading = true
      let data = {
        token_auth: sessionStorage.getItem("login"),
        document_list: document_list
      };
      return this.axios.post(this.ccapi + `/${process.env.VUE_APP_BUSINESS_FONCABSA}/admission_request/upload_documents_for_registration`, data,{ 'Content-Type': 'application/json'})
          .then(response => {
            console.log("Save: ",response);
            for(let i = 0 ; i < Object.values(document_list).length ; i++){
              this.nameDocumentsArray.push({name: document_list[i].name+'.'+document_list[i].extension ,
                type: document_list[i].abbreviation});
              if(document_list[i].abbreviation == "RPPSOCIAL"){
                this.updateEnrollmentFeePaymentDate();
              }
            }
            console.log("Save: ",response.data.response.remote_path_list);
            this.updateArrayDocumentsSelected(response.data.response.remote_path_list);
          }).catch(error => {
            console.log("ERROR: ",error);
            this.isLoading = false;
            Swal.fire({
              title: "Aviso",
              text: "Error al cargar documento",
              icon: "error",
              confirmButtonColor: '#FEB72B',
            })
          });
    },
    setDocumentSelected(documents, isDocumentSaved) {
      console.log("DOCUMENTS ",documents);
      console.log();
      this.documentsArray = {
        identifications: [],
        documents: [],
        proofIncomes: []
      };
      for (let i = 0; i < documents.length; i++) {
        if (isDocumentSaved) {
          let document_name = this.getFileNameFromURL(documents[i].url, "document_name");
          var documentItem = {
            id: documents[i].document_id,
            typeDocument: documents[i].abbreviation,
            name: document_name,
            correctName: documents[i].document_name,
            file: "",
            document_name: "Documentos_Socios",
            business_acronym:"FONCABSA",
            process:"documentos_alta_socio",
            extension: "",
            abbreviation: documents[i].abbreviation
          }
        }

        switch (documents[i].abbreviation) {
          case "IO":
            if (isDocumentSaved) {
              this.loadCheckID = true;
              console.log("setDocumentSelected2 ", this.loadCheckID);
              this.documentsArray.identifications.push(documentItem); 
            }
            break;
          case "CURP":
            this.curp = isDocumentSaved != true ? documents[i].document_name +"." +documents[i].abbreviation : documentItem.name;
            if (isDocumentSaved) {
              this.documentsArray.documents.push(documentItem);  
            }
            this.loadCheckCURP = true;
            break;
          case "CD":
            this.proofResidency = isDocumentSaved != true ? documents[i].document_name+"." +documents[i].abbreviation : documentItem.name;
            if (isDocumentSaved) {
              this.documentsArray.documents.push(documentItem);  
            }
            this.loadCheckProofResidency = true;
            break;
          case "CI":
            if (isDocumentSaved) {
              this.documentsArray.proofIncomes.push(documentItem);
            }
            this.loadCheckProofIncome = true;
            break;
          case "EC":
            this.financialStatement = isDocumentSaved != true ? documents[i].document_name+"." +documents[i].abbreviation : documentItem.name;
            if (isDocumentSaved) {
              this.documentsArray.documents.push(documentItem);  
            }
            this.loadCheckFinancialStatement = true;
            break;
          case "RFCFIEL":
            this.rfc = isDocumentSaved != true ? documents[i].document_name+"." +documents[i].abbreviation : documentItem.name;
            if (isDocumentSaved) {
              this.documentsArray.documents.push(documentItem);  
            }
            this.loadCheckRFC = true;
            break;
          case "RPPSOCIAL":
            this.rppsocial = isDocumentSaved != true ? documents[i].document_name+"." +documents[i].abbreviation : documentItem.name;
            if (isDocumentSaved) {
              this.documentsArray.documents.push(documentItem);  
            }
            this.loadCheckRPPSocial = true;
            break;
          case "AUDSCTOAHORRO":
            this.audsctoAhorro = isDocumentSaved != true ? documents[i].document_name+"." +documents[i].abbreviation : documentItem.name;
            if (isDocumentSaved) {
              this.documentsArray.documents.push(documentItem);  
            }
            this.loadCheckAudsctoAhorro = true;
            break;
          default:
            break;
        }
      }
      this.validate();
      this.isLoading=false;
    },
    validate(){
      if (!this.loadCheckID || !this.loadCheckCURP || !this.loadCheckProofResidency) {
        this.isReady = false;
      } else {
        this.isReady = true;
      }

      console.log("ready: ", this.isReady)
    },
    async showMessage(){
      this.isLoading=false;
      this.user_is_validated = false;
      await Swal.fire({
        title: "Aviso",
        text: "Para continuar con la carga de documentos favor de verificarse.",
        icon: "info",
        confirmButtonColor: '#FEB72B',
      }).then((result) => {
        if (result.value) {
          // window.location.href = "/cuenta/registro-datos-personales"
          this.$router.push({path:"/cuenta/registro-datos-personales"});
        }
      });
    },
    async validateInfoDocuments(){
      console.log("Estoy validado y voy a guardar la identificacion", this.infoDocuments);
      console.log("Estoy validado y voy a guardar la identificacion", this.infoDocuments);
      await this.getDocuments();
      if(!this.loadCheckID){ //sino hay identificación guardada, se busca y se guarda
        this.getAndSaveIdentification();
      }
    },
      getAndSaveIdentification() {
      console.log("get and save identificaction");
      this.isLoading = true
      var token = sessionStorage.getItem("login");
      let data_verification_id = this.data_verification_id;

      getDataVerificationMati(
          data_verification_id
      ).then(({ data }) => {
        var dataResponse = data;
        this.matiStatusVerification = data.response.result.identity.status;
        console.log("Estatus mati: ",this.matiStatusVerification);
        if(this.matiStatusVerification != undefined) {
          if (this.matiStatusVerification == "verified") {
            this.saveIdentification(dataResponse);
          } else {
            this.isLoading = false;
          }
          this.registerExist = true;
        } else {
          this.registerExist = false;
          this.isLoading = false;
        }
      })
          .catch(error => {
            this.isLoading = false
            console.log("Error: ",error);
            Swal.fire({
              title: "Aviso",
              text:
                  "Se presentó un error. No fue posible recuperar los datos de la validación.",
              icon: "error",
              confirmButtonColor: '#FEB72B',
            });
          })
    },
    async saveIdentification(dataResponse){
      var documentType = JSON.stringify(dataResponse.response.result.documents[0].type).replace(/['"]+/g, '');
      var document = {};
      if(documentType=="passport") {
        let photo1 = dataResponse.response.result.documents[0].photos[0]
        var self = this;
        await this.getImageByUrl(photo1, function(base64, extension) {
          //self.save(base64, "pasaporte", extension, "IO");
          document = {
            name: "pasaporte",
            extension: extension,
            abbreviation: "IO",
            admission_request_id: self.admission_id,
            remote_folder: "documentos_alta_socios",
            file: base64
          };
          self.save([document]).then((response) => {
            self.isLoading = false;
          });
        });
      } else {
        let photo1 = dataResponse.response.result.documents[0].photos[0]
        let photo2 = dataResponse.response.result.documents[0].photos[1]
        var self = this;
        try {
            await this.getImageByUrl(photo1, function(base64, extension) {
              document = {
                name: "INE frontal",
                extension: extension,
                abbreviation: "IO",
                admission_request_id: self.admission_id,
                remote_folder: "documentos_alta_socios",
                file: base64
              };
              self.save([document]).then((response) => {
                self.getImageByUrl(photo2, function(base64, extension) {
                  document = {
                    name: "INE trasera",
                    extension: extension,
                    abbreviation: "IO",
                    admission_request_id: self.admission_id,
                    remote_folder: "documentos_alta_socios",
                    file: base64
                  };
                  self.save([document]).then((response) => {
                    self.isLoading = false;
                  });
                });
              });
            });
        }catch (e){
          console.log("Error: ",e);
          Swal.fire({
            title: "Aviso",
            text:
                "Se presentó un error. No fue posible recuperar los datos de la validación.",
            icon: "error",
            confirmButtonColor: '#FEB72B',
          });
        }
      }
    },
    async getImageByUrl(url, callback) {
      var xhr = new XMLHttpRequest();
      xhr.onload = function() {
        var reader = new FileReader();
        reader.onloadend = function() {
          var ext = reader.result.split(';')[0];
          var extFinal = ext.split('/')[1];
          callback(reader.result.split(',')[1], extFinal);
        }
        reader.readAsDataURL(xhr.response);
      };
      xhr.open('GET', url);
      xhr.responseType = 'blob';
      await xhr.send();
    },
    updateArrayDocumentsSelected(documents_list) {
      console.log("documents_list: ",documents_list);
      for (let i = 0; i < documents_list.length; i++) {
        //let name = this.getFileNameFromURL(url, "document_name");
        var documentItem = {
          id: documents_list[i].id,
          typeDocument: documents_list[i].abbreviation,
          name: documents_list[i].name,
          correctName: documents_list[i].name,
          file: documents_list[i].remote_path,
          document_name: "Documentos_Socios",
          business_acronym:"FONCABSA",
          process:"documentos_alta_socio",
          extension: documents_list[i].extension,
          abbreviation: documents_list[i].abbreviation
        }

        switch (documents_list[i].abbreviation) {
          case "IO":
            this.loadCheckID = true;
            this.documentsArray.identifications.push(documentItem);
            break;
          case "CURP":
            this.curp = documentItem.name +"."+documentItem.extension;
            this.documentsArray.documents.push(documentItem);
            this.loadCheckCURP = true;
            break;
          case "CD":
            this.proofResidency = documentItem.name +"."+documentItem.extension ;
            this.documentsArray.documents.push(documentItem);
            this.loadCheckProofResidency = true;
            break;
          case "CI":
            this.documentsArray.proofIncomes.push(documentItem);
            this.loadCheckProofIncome = true;
            break;
          case "EC":
            this.financialStatement = documentItem.name+"." + documentItem.extension;
            this.documentsArray.documents.push(documentItem);
            this.loadCheckFinancialStatement = true;
            break;
          case "RFCFIEL":
            this.rfc = documentItem.name +".Delete" +documentItem.extension;
            this.documentsArray.documents.push(documentItem);
            this.loadCheckRFC = true;
            break;
          case "RPPSOCIAL":
            this.rppsocial = documentItem.name +"."+documentItem.extension;
            this.documentsArray.documents.push(documentItem);
            this.loadCheckRPPSocial = true;
            break;
          case "AUDSCTOAHORRO":
            this.audsctoAhorro = documentItem.name +"."+documentItem.extension;
            this.documentsArray.documents.push(documentItem);
            this.loadCheckAudsctoAhorro = true;
            break;
          default:
            break;
        }
      }
      this.validate();
      // this.isLoading=false;
    },
    async getAdmissionRequestById(){
      this.axios.get(this.ccapi + `/${process.env.VUE_APP_BUSINESS_FONCABSA}/admission_request/get_request_by_id`, {
        params:{
          token_auth: sessionStorage.getItem("login"),
          admission_request_id: this.getRequestIdFromParams()
        }
      }, { 'Content-Type': 'application/json' })
      .then(response => {
        this.assisted_request = response.data.response.admission_request.assisted_request
        this.admission_status = response.data.response.admission_request.status
      }).catch(error => {
      });
    },
    getRequestIdFromParams(){
      return this.$route.params.requestId;
    },
    getPayment() {
      this.objectClassStripe.hasPayment(sessionStorage.getItem("login"), this.admission_id).then(response => {
          this.hasPayment = response.data.response.has_payment;
          console.log("tiene pago: "+this.hasPayment)
      }).catch(error => {
      });
    },
    selectDate(event){
      if((moment(event._d).format("YYYY-MM-DD")) > (moment().format("YYYY-MM-DD"))){
        Swal.fire({
          title: "Aviso",
          text:
            "La fecha de depósito tiene que ser menor o igual a la actual.",
          icon: "warning",
          confirmButtonColor: '#FEB72B',
        });
        this.paymentDate = ""
        return;
      }
      else{
        this.paymentDate = moment(event._d).format("DD/MM/YYYY")
      }
    },
    updateEnrollmentFeePaymentDate(){
      this.isLoading = true;
      this.axios.post(this.ccapi + `/${process.env.VUE_APP_BUSINESS_FONCABSA}/admission_request/update_enrollment_fee_payment_date`, {
        token_auth: sessionStorage.getItem("login"),
        admission_request_id: this.admission_id,
        enrollment_fee_payment_date: this.paymentDate,
        enrollment_fee_method: "transferencia"
      },{ 'Content-Type': 'application/json'})
      .then(response => {
        console.log("Se actualizo campo enrollment_fee_payment_date en base de datos")
        var admission_request_information = this.getJsonFromLocalStorageBy(`request_${this.getRouterParams()}`)
        admission_request_information.enrollment_fee_payment_date = this.paymentDate
        this.setToLocalStorage(`request_${this.getRouterParams()}`, admission_request_information)
      }).catch( error => {
      }).finally(() => {
        this.isLoading = false;
      });
    },
    setToLocalStorage(item_id, value_item) {
      localStorage.setItem(item_id, JSON.stringify(value_item));
    },
    getJsonFromLocalStorageBy(item_id) {
      let _json = JSON.parse(localStorage.getItem(item_id));
      return _json;
    }
  }
}
</script>

<style scoped>
.register{
  border: 1px solid #999999;
  background-color: red;
  color: #666666;
}
.center {
  padding: 00px 0;
  border: 3px solid #ffffff;;
  text-align: center;
}

@media (min-width: 766px) {
  .collapse.dont-collapse-sm {
    display: block;
    height: auto !important;
    visibility: visible;
  }
}

#button_popups{
      border-top-left-radius: 15px;
      border-top-right-radius: 15px;
      border-bottom-left-radius: 15px;
      border-bottom-right-radius: 15px;
      background-color: #FEB72B;
      width: 250px;
      height: 30px;
      padding-top: 0%;
  }

button {
  outline: none !important;
}

h2 {font-size:36px;margin:0 0 10px 0}
p {margin:0 0 10px 0}
table.width200,table.rwd_auto {width:100%;margin:0 0 50px 0;}
  .width200 th,.rwd_auto th {background:#fff;padding:5px;text-align:left;}
  .width200 td,.rwd_auto td {padding:5px;text-align:left}
  .width200 tr:last-child td, .rwd_auto tr:last-child td{border:0;}
  .width200 thead tr {border-bottom:1px solid #ccc;color:#726659;font-size:16px !important;font-family:Roboto !important;font-weight: 400 !important;}
  .width200 tbody td {color:#726659;}

@media only screen and (max-width: 1024px)  
{
  table.width200, .width200 thead, .width200 tbody, .width200 th, .width200 td, .width200 tr { display: block; }
  .width200 thead tr { position: absolute;top: -9999px;left: -9999px; }
  .width200 tr { border-bottom: 1px solid #ccc; }
  .width200 td { position: relative;padding-left: 40%;text-align:left; }
  .width200 td:before {  position: absolute; top: 6px; left: 6px; width: 45%; padding-right: 10px; white-space: nowrap;}
  .width200 td:nth-of-type(1):before { 
    content: "Documento:"; 
    color: #726659;
    font-family:Roboto;
    font-weight: 700;
  }
  .width200 td:nth-of-type(2):before { 
    content: "Estatus:"; 
    color: #726659;
    font-family:Roboto;
    font-weight: 700;
  }
  .width200 td:nth-of-type(3):before { 
    content: "Nombre:"; 
    color: #726659;
    font-family:Roboto;
    font-weight: 700;
  }
  .width200 td:nth-of-type(4):before { content: ""; }
  .descarto {display:none;}
  .fontsize {font-size:10px;}
}

/* table.subtable,table.rwd_auto {width:100%;margin:0 0 50px 0;}
  .subtable th,.rwd_auto th {background:#fff;padding:5px;text-align:left;}
  .subtable td,.rwd_auto td {padding:5px;text-align:left}
  .subtable tr:last-child td, .rwd_auto tr:last-child td{border:0;}
  .subtable thead tr {border-bottom:1px solid #ccc;}
  .subtable tbody td {color:#8e8e8e;} */

@media only screen and (max-width: 1024px)  
{
  .subtable, .subtable thead, .subtable tbody, .subtable th, .subtable td, .subtable tr { display: block; }
  .subtable thead tr { position: absolute;top: -9999px;left: -9999px; }

  .subtableid td,.subtableid th {
    display: block;
    padding: 1rem;
    text-align:left;
  }
  
  .subtable td {position: relative;padding-left: 40%;text-align:left; }
  .subtable td:before {  position: absolute; top: 6px; left: 6px; width: 45%; padding-right: 10px; white-space: nowrap;}
  .subtable td:nth-of-type(1):before { content: ""; }
  .subtable td:nth-of-type(2):before { content: ""; }
  .subtable td:nth-of-type(3):before { content: ""; }
  .subtable td:nth-of-type(4):before { content: ""; }
  .descarto {display:none;}
  .fontsize {font-size:10px;}
}

@media only screen and (max-width: 1024px)  
{
  .subtableid, .subtableid thead, .subtableid tbody, .subtableid th, .subtableid td, .subtableid tr { display: block; }
  .subtableid thead tr { position: absolute;top: -9999px;left: -9999px; }
  
  .subtableid td {position: relative;padding-left: 40%;text-align:left; }
  .subtableid td:before {  position: absolute; top: 6px; left: 6px; width: 45%; padding-right: 10px; white-space: nowrap;}
  .subtableid td:nth-of-type(1):before { content: ""; }
  .subtableid td:nth-of-type(2):before { content: ""; }
  .subtableid td:nth-of-type(3):before { content: ""; }
  .subtableid td:nth-of-type(4):before { content: ""; }
  .descarto {display:none;}
  .fontsize {font-size:10px;}
}
/* Smartphones (portrait and landscape) ----------- */
@media only screen and (min-width : 320px) and (max-width : 480px) 
{
  .descarto {display:none;}
}
/* iPads (portrait and landscape) ----------- */
@media only screen and (min-width: 768px) and (max-width: 1024px) 
{
  .descarto {display:none;}
  .fontsize {font-size:10px}
}

.btnMain {
  text-decoration:none;
  font-family:Roboto;
  font-size: 13px;
  color:#8e8e8e;
  padding-top:5px;
  padding-bottom:5px;
  background-color:#FFF;
  border: 0px solid;
  border-color:#8e8e8e;
  border-width: 1px;border-radius:35px;
  width: 12em;
  outline:none;
  float:right;
  margin-right:15px;
}

.btnMainProofIncomeNoResponsive {
  text-decoration:none;
  font-family:Roboto;
  font-size: 13px;
  color:#8e8e8e;
  padding-top:5px;
  padding-bottom:5px;
  background-color:#FFF;
  border: 0px solid;
  border-color:#8e8e8e;
  border-width: 1px;border-radius:35px;
  width: 12em;
  outline:none;
  float:right;
  margin-right: 10px;
  visibility: visible;
}

.btnMainNoResponsive {
  text-decoration:none;
  font-family:Roboto;
  font-size: 13px;
  color:#8e8e8e;
  padding-top:5px;
  padding-bottom:5px;
  background-color:#FFF;
  border: 0px solid;
  border-color:#8e8e8e;
  border-width: 1px;border-radius:35px;
  width: 12em;
  outline:none;
  float:right;
  margin-right: 15px;
  visibility: visible;
}

.btnDeleteDocument {
  text-decoration:none;
  font-family:Roboto;
  font-size: 13px;
  color: red;
  padding-top:5px;
  padding-bottom:5px;
  background-color:#FFF;
  border: 0px solid;
  border-color:red;
  border-width: 1px;
  border-radius:35px;
  width: 12em;
  outline:none;
  float:right;
  margin-right:15px;
}

.btnLoadAfterDocuments {
  text-decoration:none;
  font-family:Roboto;
  font-size: 14px;
  font-weight: bold;
  color:#FEB72B ;
  padding-top:5px;
  padding-bottom:5px;
  background-color:#FFF;
  border: 0px solid;
  border-color:#FEB72B;
  border-width: 1px;
  border-radius:7px;
  height: 40px;
  width: 20em;
  outline:none;
}

.btnAddDocuments {
  text-decoration:none;
  font-family:Roboto;
  font-size: 14px;
  font-weight: bold;
  color:#FFF ;
  padding-top:5px;
  padding-bottom:5px;
  background-color:#FEB72B;
  border: 0px solid;
  border-color:#FEB72B;
  border-width: 1px;
  border-radius:7px;
  height:40px;
  width: 20em;
  outline:none;
}

@media (max-width: 500px) {
  .btnLoadAfterDocuments {
    height: 50px !important;
  }
  .btnAddDocuments {
    height: 50px !important;
  }
}

.btnFile {
	width: 0.1px;
	height: 0.1px;
	opacity: 0;
	overflow: hidden;
	position: absolute;
	z-index: -1;
  outline:none;
}

.btnFile + label {
  text-decoration:none;
  font-family:Roboto;
  font-size: 13px;
  color:#8e8e8e;
  padding-top:5px;
  padding-bottom:5px;
  background-color:#FFF;
  border: 0px solid;
  border-color:#8e8e8e;
  border-width: 1px;border-radius:35px;
  width: 12em;
  text-align: center;
}

.btnFile + label {
	cursor: pointer; /* "hand" cursor */
}

.table-scroll{
  width:100%;
  /* display: block; */
  empty-cells: show;
  /* Decoration */
  border-spacing: 0;
}

.table-scroll thead{
  position:relative;
  display: block;
  width:100%;
  overflow-y: scroll;
}

.table-scroll tbody{
  /* Position */
  display: block; position:relative;
  width:100%; overflow-y:scroll;
  /* Decoration */
}

.table-scroll tr{
  width: 100%;
  display:flex;
}

::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey; 
  border-radius: 10px;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #8E8E8E; 
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #8E8E8E; 
}

.table-scroll td,.table-scroll th{
  /* flex-basis:100%; */
  display: block;
  padding: 1rem;
  text-align:left;
}

.table-scroll2 td,.table-scroll2 th{
  display: block;
  padding: 1rem;
  text-align:left;
}
/* Other options */

.table-scroll.small-first-col td:first-child,
.table-scroll.small-first-col th:first-child{
  flex-basis:20%;
  flex-grow:1;
}

.table-scroll tbody tr:nth-child(2n){
  
}

.body-half-screen{
  max-height: 20vh;
}

.small-col{}

.titlesProofIncome {
  word-wrap: break-word;
}

.titles {
  font-size:13px;
  font-family:Roboto;
  font-weight: 400;
  word-wrap: break-word;
}

.tdTable {
  height: 40px;
  padding-left: 0 !important;
}

.trTable {
  border-bottom: 1px solid #ccc;
  border-right: 1px solid #ccc;
  border-left: 1px solid #ccc;
  border-top: 1px solid #ccc;
  vertical-align:top;
  height:100px;
}
.trRFC {
  height:100px;
  vertical-align: middle;
  border-bottom: 1px solid #ccc;
}
.btnDeleteResponsive {
  visibility: hidden;
  float:left;
}

.btnDeleteNoResponsive {
  text-decoration:none;
  font-family:Roboto;
  font-size: 13px;
  color: red;
  padding-top:5px;
  padding-bottom:5px;
  background-color:#FFF;
  border: 0px solid;
  border-color:red;
  border-width: 1px;
  border-radius:35px;
  width: 12em;
  outline:none;
  visibility: visible;
  float:right;
}
.btnDeleteMobile {
  visibility: hidden;
  float:left;
}
.btnLoadMobile {
  visibility: hidden;
  float:left;
}
.textMati {
  color:#6ee5d3;
  font-weight:bold;
  text-align:center;
  float:right;
  margin-right:23px;
}
.tdButton {
  display:block;
}
@media only screen and (max-width: 1024px)  
{
  .trTable {
    height: 400px !important;
  }
  .trRFC {
    height:auto;
    vertical-align: middle;
    border-bottom: 1px solid #ccc;
  }
  .tdTable {
    height: auto;
    padding-left: 0px;
  }
  .btnDeleteResponsive {
    visibility: visible;
  }
  .btnDeleteNoResponsive {
    visibility: hidden;
  }
  .btnDeleteMobile {
    visibility: visible;
  }
  .btnLoadMobile {
    visibility: visible;
  }
  .btnMainNoResponsive {
    visibility: hidden;
  }
  .btnMainProofIncomeNoResponsive {
    visibility: hidden;
  }
  .body-half-screen{
    max-height: 30vh;
  }
  .btnDeleteResponsive {
    width:70px;
  }
  .btnMainProofIncomeNoResponsive {
    width:70px;
    float:none;
    margin-right: 0;
  }
  .btnMainNoResponsive{
    width:70px;
    float:none;
    margin-right: 0;
  }
  .btnDeleteDocument {
    width: 5em;
    float: none;
    margin-right: 0;
  }
  .titlesProofIncome {
    width: 300px;
  }
  .titles{
    width: 300px;
  }
  .textMati {
    text-align:left;
    float:none;
    margin-right:0;
  }
  .checkmark {
    margin: 0 !important;
  }
  .tdButton {
    display:none !important;
  }
}

@media only screen and (max-width: 630px)  
{
  .titlesProofIncome {
    width: 100px; 
  }
  .titles{
    width: 200px;
  }
}

@media only screen and (max-width: 630px)  
{
  .titlesProofIncome {
    width: 100px; 
  }
  .titles{
    width: 100px;
  }
}

#progress_bar {
  margin: 10px 0;
  padding: 3px;
  border: 1px solid #000;
  font-size: 14px;
  clear: both;
  opacity: 0;
  -moz-transition: opacity 1s linear;
  -o-transition: opacity 1s linear;
  -webkit-transition: opacity 1s linear;
}
#progress_bar.loading {
  opacity: 1.0;
}
#progress_bar .percent {
  background-color: #99ccff;
  height: auto;
  width: 0;
}

@media (max-width: 501px) {

  .next_button, .end_process, .back_button {
      width: 100% !important;
  }
}

.buttonSave {
    background-color: #4CAF50; /* Green */
    opacity: 0.7;
    border: none;
    border-radius: 5px;
    color: white;
    padding: 1px 10px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 12px;
    font-family:Lato;
    widows: 10px;
    height: 30px;
    cursor: pointer;
}
</style>